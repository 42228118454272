import "./profile/style.scss";
import { Text } from "@clipboard-health/ui-react";
import {
  IonCard,
  IonCardSubtitle,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import { Stack } from "@mui/material";
import { AgentStage } from "@src/appV2/Agents";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { formatPhone } from "@src/lib/utils";
import { getWorkerQualifications } from "@src/utils/agent";
import { book, call, mail, pencilOutline } from "ionicons/icons";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { UPDATE_PROFILE_WORKFLOW } from "./profile/constants";
import { ContractorAgreement } from "./profile/contractorAgreement";
import { ProfileImage } from "./profile/profileImage";
import { WorkWithFriends } from "./profile/workWithFriends";
import { USER_EVENTS } from "../../constants";
import { getWorkerShiftTypeLabel } from "../../constants/qualificationTypes";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { useSession } from "../store/helperHooks";

export function ProfilePage() {
  const { agent } = useSession();
  const location = useLocation();
  const history = useHistory();

  // FIXME: Replace this to use query-string to pass information instead of
  // relying on location state.
  const { isUpdateProfileImageModalOpen } = (location.state || {}) as {
    isUpdateProfileImageModalOpen: boolean;
  };

  useLogEffect(USER_EVENTS.VIEWED_PROFILE);

  const updateEmail = () => {
    logEvent(USER_EVENTS.PROFILE_CHANGE_STARTED, {
      type: "EMAIL",
    });
    localStorage.setItem("profileUpdateWorkflow", UPDATE_PROFILE_WORKFLOW.UPDATE_EMAIL);
    history.push(TabRouterPath.UPDATE_PROFILE);
  };

  const updatePhone = () => {
    logEvent(USER_EVENTS.PROFILE_CHANGE_STARTED, {
      type: "PHONE",
    });
    localStorage.setItem("profileUpdateWorkflow", UPDATE_PROFILE_WORKFLOW.UPDATE_PHONE);
    history.push(TabRouterPath.UPDATE_PROFILE);
  };

  const workerQualifications: string = useMemo(() => {
    if (!agent?.licensesData?.length) {
      return (
        getWorkerShiftTypeLabel(agent?.qualification) ||
        `Other (${getWorkerShiftTypeLabel(agent?.otherQualification)})`
      );
    }

    const workerQualifications = getWorkerQualifications(agent);
    const workerQualificationsLabel = workerQualifications
      .map((qualification) => getWorkerShiftTypeLabel(qualification))
      .sort();
    return workerQualificationsLabel.join(", ");
  }, [agent]);

  const isSoftDeletionEnabled = useCbhFlag(CbhFeatureFlag.HCP_SOFT_DELETION, {
    defaultValue: false,
  });

  const agentHasRequestedDeletion =
    agent?.requestedAccountDeletion?.requested || agent?.stage === AgentStage.DELETION_REQUESTED;

  if (!agent) {
    return <IonSpinner color="light" />;
  }

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Profile"
            leftCta={<BackButtonLink defaultBackTo={TabRouterPath.ACCOUNT} />}
          />
        }
      >
        <Stack>
          <Stack spacing={2} alignItems="center">
            <ProfileImage
              userId={agent.userId}
              isUpdateProfileImageModalOpen={isUpdateProfileImageModalOpen}
              isProfilePictureUploaded={agent.isProfilePictureUploaded}
            />
            <Text variant="h3">{agent.name?.toUpperCase()}</Text>
          </Stack>
          <IonCard>
            <IonItem button onClick={updateEmail}>
              <IonIcon icon={mail} />
              <IonLabel class="ion-margin">
                <IonCardSubtitle>Email</IonCardSubtitle>
                <h6>{agent.email}</h6>
              </IonLabel>
              <IonIcon icon={pencilOutline} />
            </IonItem>
            <IonItem button onClick={updatePhone}>
              <IonIcon icon={call} />
              <IonLabel class="ion-margin">
                <IonCardSubtitle>Phone</IonCardSubtitle>
                <h6>{formatPhone(agent.phone as string)}</h6>
              </IonLabel>
              <IonIcon icon={pencilOutline} />
            </IonItem>
            <IonItem>
              <IonIcon icon={book} />
              <IonLabel class="ion-margin">
                <IonCardSubtitle>Qualification</IonCardSubtitle>
                <h6>{workerQualifications}</h6>
              </IonLabel>
            </IonItem>
          </IonCard>

          <ContractorAgreement />
          <WorkWithFriends />

          <IonCard>
            <IonItem
              button
              onClick={() => {
                if (isSoftDeletionEnabled) {
                  history.push("/home-v2/accounts/delete-account");
                } else {
                  history.push(TabRouterPath.DELETE_DATA);
                }
              }}
              disabled={agentHasRequestedDeletion}
            >
              <IonLabel>
                {agentHasRequestedDeletion
                  ? "Account Deletion Request Pending"
                  : "Delete My Account"}
              </IonLabel>
            </IonItem>
          </IonCard>
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
