import "./style.scss";
import { IonButton, IonCheckbox, IonCol, IonFooter, IonSpinner, IonText } from "@ionic/react";
import { useToast } from "@src/appV2/lib";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createDeletionRequest } from "./api";
import { updateAgentProfileState } from "../../onboardingStripe/actions";
import { Store } from "../../store/store.model";

interface ProfileUpdateStepProps {
  stepFinished: () => void;
}
export function ConfirmDeletion(props: ProfileUpdateStepProps) {
  const { stepFinished } = props;
  const [enableDeleteButton, setEnableDeleteButton] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const { showErrorToast } = useToast();
  const dispatch = useDispatch();
  const updateWorkerCache = useUpdateWorkerCache();

  const handleAccountDeletionConfirmation = ({ detail }) =>
    setEnableDeleteButton(detail?.checked || false);

  const { name, email, firstName } = useSelector((state: Store) => state?.session?.agent || {});

  const workerName: string = firstName || name?.split(" ")[0] || name || "";

  const sendDeletionHandler = async () => {
    try {
      setIsSendingRequest(true);

      const response = await createDeletionRequest();

      if (response === undefined) {
        showErrorToast(
          "Account deletion request can not be handled at the moment. Please try again later."
        );
        return;
      }
      if (!response?.success) {
        showErrorToast("Request already created");
        return;
      }

      updateWorkerCache({
        requestedAccountDeletion: {
          requested: true,
          createdAt: null,
          previousStage: null,
        },
      });
      dispatch(
        updateAgentProfileState({
          requestedAccountDeletion: {
            requested: true,
            createdAt: null,
            previousStage: null,
          },
        })
      );

      stepFinished();
    } catch (error) {
      showErrorToast("Unexpected error occurred while creating deletion request");
    } finally {
      setIsSendingRequest(false);
    }
  };

  return (
    <div className="signup-content content-layout adjust-form-height no-scroll">
      <IonCol sizeMd="" offsetMd="2" offsetLg="4" sizeLg="4">
        <IonText color="dark">
          <h3 className="ion-margin-bottom">Deleting your Clipboard Health Account</h3>
        </IonText>
        {workerName && (
          <IonText>
            <p className="ion-margin-top">{workerName},</p>
          </IonText>
        )}

        <p className="ion-margin-top">
          Please Note that if Clipboard Health deletes your personal information, you will no longer
          be able to access your account, all associated personal information, Clipboard Health's
          app or Clipboard Health's online marketplace.
        </p>

        <IonText className="ion-margin-top">
          <p>
            Upon finalizing your request, we will move forward with processing your data deletion by
            emailing you at {email} within forty-five(45) calender days of your request, and either
            (A) confirm that your data has been deleted; or (B) notify you that we are denying your
            data deletion request in whole or in part, and simultaneously provide an explanation for
            the denial.
          </p>
        </IonText>

        <IonText>
          <p>Thank you and we wish you all the best,</p>
        </IonText>
        <IonText>
          <p>Clipboard Health</p>
        </IonText>
      </IonCol>
      <IonFooter className="ion-margin-top">
        <IonText className="confirm-delete-check-container">
          <div className="confirm-delete-check">
            <IonCheckbox
              aria-label="Confirm delete check"
              mode="md"
              color="dark"
              checked={enableDeleteButton}
              onIonChange={handleAccountDeletionConfirmation}
            />
          </div>

          <em>
            I understand that deleting my account is not reversible and I will no longer be able to
            login to Clipboard Health. If I want to work through Clipboard Health again I will need
            to create a new account
          </em>
        </IonText>

        <div className="signupform-footer footer-container">
          <IonButton
            color="danger"
            expand="block"
            shape="round"
            fill={enableDeleteButton ? "solid" : "outline"}
            disabled={!enableDeleteButton || isSendingRequest}
            className="deletion-button ion-margin-top "
            onClick={sendDeletionHandler}
          >
            {isSendingRequest ? (
              <IonSpinner slot="end" class="ion-margin-start" name="lines" />
            ) : (
              <IonText> Request Account Deletion</IonText>
            )}
          </IonButton>
        </div>
      </IonFooter>
    </div>
  );
}
